<template>
  <div>
    <footer class="footer">
      <div>
        <div v-if="isLoggedIn">
          App Data:
          <span v-if="isUpToDate" :title="MESSAGES.SUCCESS.APP_DATA_UPDATED">
            Up to Date
            <i class="fa-solid fa-circle-check status-online"></i>
          </span>
          <span v-else>
            Local data <i class="fas fa-location-dot status-local"></i>
          </span>
        </div>
        <div v-if="isLoggedIn" class="sync-status" @click="openDataSyncModal">
          Last sync: {{ LastSyncDateString }}
          <span
            v-if="syncStatusState.isSyncing"
            :title="MESSAGES.INFO.APPLICATION_SYNC_IN_Progress"
          >
            <i class="fa fa-arrows-rotate rotate-animation"></i>
          </span>
          <span
            v-else-if="syncStatusState.success"
            class="status-online"
            :title="MESSAGES.SUCCESS.APP_DATA_SYNCED + LastSyncDateString"
          >
            <i class="fas fa-signal"></i>
          </span>

          <span
            v-else
            class="status-local"
            :title="MESSAGES.ERROR.LOST_CONNECTION"
          >
            <i class="fas fa-times"></i>
          </span>
        </div>
        <div>
          App Version: <b id="app-version-id">0.{{ appVersion }}</b>
        </div>
      </div>

      <WorkingHistory></WorkingHistory>
      <ProductCatalog></ProductCatalog>
      <data-sync-modal />
      <image-modal />
      <order-history-modal />
      <files-upload-modal />
      <transfer-items-modal />
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, inject } from "vue";
import { storeToRefs } from "pinia";
import { useOrderFormStore } from "@/stores/orderFormStore";
import { formattedTimeDifference } from "@/utils/utils.js";
import { MESSAGES } from "@/utils/messages.js";
import DataSyncModal from "@/views/Modals/DataSyncModal.vue";
import ImageModal from "@/views/Modals/ImageModal.vue";
import OrderHistoryModal from "@/views/Modals/OrderHistoryModal.vue";
import WorkingHistory from "@/views/Modals/WorkingHistory.vue";
import ProductCatalog from "@/views/Modals/ProductCatalog.vue";
import FilesUploadModal from "@/views/Modals/FilesUploadModal.vue";
import TransferItemsModal from "./Modals/TransferItemsModal.vue";
const EventBus = inject("EventBus");
const appVersion = ref("");
const store = useOrderFormStore();
const { syncStatusState, isLoggedIn } = storeToRefs(store);
const LastSyncDateString = ref("");

const props = defineProps({
  isUpToDate: { type: Boolean, required: true },
});
const openDataSyncModal = () => {
  EventBus.$emit("show-data-sync-modal");
};
watch(
  () => syncStatusState.value.lastSyncDateTime,
  (newValue) => {
    LastSyncDateString.value = formattedTimeDifference(newValue);
  },
  { deep: true }
);
onMounted(() => {
  appVersion.value = process.env.VUE_APP_VERSION;
});
</script>
<template>
  <div :class="modalClasses" tabindex="-1">
    <div
      class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Transfer Items</h5>
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body">
          <p class="text-muted">
            Transfer order items, notes and files to another Point Of Sale.
          </p>
          <div class="item-list">
            <div class="item-row pos-picker-wrapper">
              <div class="list-item">
                <span class="item-label">Code:</span>
                <span class="item-value">
                  <point-of-sale-picker
                    @pos-selected="handlePOSSelection"
                    @input-changes="resetFormData"
                    :allow-add-new="false"
                  />
                </span>
              </div>
            </div>

            <div class="item-row" v-if="formData.customerName">
              <div class="list-item">
                <span class="item-label">Transfer to:</span>
                <span class="item-value">{{ formData.customerName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="confirmTransfer"
            :disabled="!formData.customerName || isLoading"
          >
            <span v-if="isLoading">
              <i class="fa fa-arrows-rotate rotate-animation mr-2"></i>
              Processing...
            </span>
            <span v-else>Confirm</span>
          </button>
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  inject,
  onMounted,
  onBeforeUnmount,
  reactive,
} from "vue";
import PointOfSalePicker from "@/components/PointOfSale/PointOfSalePicker.vue";
import { WorkingItemRepo } from "@/services/DbAccess/WorkingItemRepo";
import { OrderNoteRepo } from "@/services/DbAccess/OrderNoteRepo";
import { useOrderFormStore } from "@/stores/orderFormStore";
import { storeToRefs } from "pinia";
import { errorToast, successToast, infoToast } from "@/utils/toast-utils.js";
import { FileUploadRepo } from "@/services/DbAccess/FileUploadRepo";
import { PointsOfSaleRepo } from "@/services/DbAccess/PointOfSaleRepo";

const store = useOrderFormStore();
const { posCode, userStoreId } = storeToRefs(store);
const EventBus = inject("EventBus");

const showModal = ref(false);
const isLoading = ref(false);
const formData = reactive({
  selectedPOS: null,
  customerName: "",
});

const modalClasses = computed(() => ({
  "modal fade show": showModal.value,
  "modal fade": !showModal.value,
}));

function openModal() {
  showModal.value = true;
  resetFormData();
  if (document.body) {
    document.body.style.overflow = "hidden";
  }
}

function closeModal() {
  showModal.value = false;
  if (document.body) {
    document.body.style.overflow = "";
  }
  resetFormData();
}

function resetFormData() {
  Object.assign(formData, {
    selectedPOS: null,
    customerName: "",
  });
}

function handlePOSSelection(code, ctName) {
  if (!code || !ctName) {
    return;
  }
  if (code === posCode.value) {
    infoToast("You can't transfer items to the same POS. Choose another POS.");
    return;
  }
  formData.selectedPOS = code;
  formData.customerName = ctName;
}

async function confirmTransfer() {
  if (!formData.selectedPOS || !posCode.value || !formData.customerName) {
    return;
  }

  isLoading.value = true;

  try {
    await ensurePointOfSaleExists();
    await transferItems();
    await transferNotes();
    await transferFiles();
    await new Promise((resolve) => setTimeout(resolve, 1500));
    await OrderNoteRepo.clear(posCode.value);
    EventBus.$emit("switch-selected-pos", formData.selectedPOS);

    closeModal();
  } catch (error) {
    errorToast("An error occurred during transfer.");
    console.error("Transfer error:", error);
  } finally {
    isLoading.value = false;
  }
}

async function ensurePointOfSaleExists() {
  const pointOfSaleExist = await PointsOfSaleRepo.find(
    formData.selectedPOS,
    userStoreId.value
  );
  if (!pointOfSaleExist) {
    throw new Error("Failed to create new Point Of Sale.");
  }
}

async function transferItems() {
  const result = await WorkingItemRepo.transferItems(
    posCode.value,
    formData.selectedPOS
  );
  if (!result) {
    throw new Error("Failed to transfer order items. Try again.");
  }
  successToast("Items transferred successfully.");
}

async function transferNotes() {
  await OrderNoteRepo.transferNotes(posCode.value, formData.selectedPOS);
}

async function transferFiles() {
  await FileUploadRepo.transferFiles(posCode.value, formData.selectedPOS);
}

onMounted(() => {
  EventBus.$on("show-transfer-items-modal", openModal);
});

onBeforeUnmount(() => {
  EventBus.$off("show-transfer-items-modal", openModal);
});
</script>

<style lang="scss" scoped>
.modal-content,
.modal-body,
.item-list {
  overflow: visible !important;
}

.pos-picker-wrapper {
  position: relative;
  z-index: 1060;

  :deep(.dropdown-menu) {
    position: absolute !important;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
  }
}

.list-item {
  display: flex;
  align-items: center !important;
}

.item-label {
  display: inline-block !important;
  margin-right: 8px;
  width: unset !important;
}

.form-check-input {
  font-size: 16px;
}

.btn-primary {
  min-width: 100px;
}

.fa-arrows-rotate {
  margin-right: 0.5rem;
}
</style>
// FileUploadRepo.js
import db from "./dbConfig";

const TABLE_EXPIRY_TIME = {
  FileUpload: 6 * 24 * 60 * 60 * 1000,
};

export class FileUploadRepo {
  static async list(pointOfSaleCode) {
    await db.Files.where("createdAt")
      .below(Date.now() - TABLE_EXPIRY_TIME.FileUpload)
      .delete();
    return await db.Files.where("pointOfSaleCode")
      .equals(pointOfSaleCode)
      .toArray();
  }

  static async transferFiles(currentPointSaleCode, newPointSaleCode) {
    try {
      const files = await this.list(currentPointSaleCode);
      if (!files) return true;

      for (const file of files) {
        const newFile = { ...file };
        newFile.pointOfSaleCode = newPointSaleCode;
        await db.Files.put(newFile);
      }

      return true;
    } catch (error) {
      console.error("Error transferFiles: ", error);
      return false;
    }
  }
  static async listAsAttahcments(pointOfSaleCode) {
    const attachments = await this.list(pointOfSaleCode);
    let filteredAttachments =
      attachments == null || attachments.length == 0
        ? []
        : attachments
            .filter((item) => item.url != null && item.url != "")
            .map((item) => {
              return {
                name: item.fileName,
                url: item.url,
              };
            });
    return filteredAttachments;
  }
  static async save(pointOfSaleCode, fileName, status, url, filePath) {
    try {
      const createdAt = Date.now();

      const existingFile = await db.Files.where("[pointOfSaleCode+fileName]")
        .equals([pointOfSaleCode, fileName])
        .first();

      if (existingFile) {
        await db.Files.update(existingFile.id, {
          url: url,
          filePath: filePath,
          status: status,
        });
      } else {
        await db.Files.put({
          pointOfSaleCode: pointOfSaleCode,
          fileName: fileName,
          url: url,
          filePath: filePath,
          status: status,
          createdAt: createdAt,
        });
      }
      return { success: true, createdAt: createdAt };
    } catch (error) {
      console.error(error);
      return { success: false, message: "Error saving file" };
    }
  }

  static async delete(pointOfSaleCode, fileName) {
    await db.Files.where("[pointOfSaleCode+fileName]")
      .equals([pointOfSaleCode, fileName])
      .delete();
  }
  static async clear(pointOfSaleCode) {
    await db.Files.where("pointOfSaleCode").equals(pointOfSaleCode).delete();
  }
}
